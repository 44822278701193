export const VERSIONS = [
  {
    "version": "2.10.2",
    "date": "02-14-2025",
    "changes": [
      "Fix issue with product drawer cad/spec files.",
    ]
  },
  {
    "version": "2.10.1",
    "date": "01-31-2025",
    "changes": [
      "Fix issue with delete button not showing on product drawers",
    ]
  },
  {
    "version": "2.10.0",
    "date": "01-15-2025",
    "changes": [
      "Change website backend service to use new API",
    ]
  },
  {
    "version": "2.9.3",
    "date": "12-13-2024",
    "changes": [
      "Fix issue with company list page not opening.",
    ]
  },
  {
    "version": "2.9.2",
    "date": "11-15-2024",
    "changes": [
      "Fix issue with contact filter dropdowns",
    ]
  },
  {
    "version": "2.9.1",
    "date": "11-07-2024",
    "changes": [
      "Fix issue with companies filtered by assigned to",
    ]
  },
  {
    "version": "2.9.0",
    logout: true,
    "date": "11-01-2024",
    "changes": [
      "Contacts & Companies can be assigned to multiple people",
      "Contacts & Companies can now be assigned a brand."
    ]
  },
  {
    "version": "2.8.0",
    "date": "09-30-2024",
    "changes": [
      "Updates to website pages.",
    ]
  },
  {
    "version": "2.7.3",
    "date": "07-02-2024",
    "changes": [
      "Fixes to sales report form when adding through pipeline form.",
    ]
  },
  {
    "version": "2.7.2",
    "date": "05-23-2024",
    "changes": [
      "Small improvements to user dropdowns.",
    ]
  },
  {
    "version": "2.7.1",
    "date": "11-20-2023",
    "changes": [
      "Fixed issue with company not opening.",
    ]
  },
  {
    "version": "2.7.0",
    "date": "11-09-2023",
    "changes": [
      "Improvements to contacts and companies table. New filter layout and improved results.",
    ]
  },
  {
    "version": "2.6.3",
    "date": "11-06-2023",
    "changes": [
      "Added a new field in Home page form to change Sales Banner link for the website.",
      "Small visual improvements to Sales Report Form text fields.",
    ]
  },
  {
    "version": "2.6.2",
    "date": "10-23-2023",
    "changes": [
      "Fix filter for contacts by group. Enhancements in the works to improve speed and complexity."
    ]
  },
  {
    "version": "2.6.1",
    "date": "09-26-2023",
    "changes": [
      "Add phone number to contact us form submission table"
    ]
  },
  {
    "version": "2.6.0",
    "date": "09-26-2023",
    "changes": [
      "Add trade program form number to submission table"
    ]
  },
  {
    "version": "2.5.0",
    "date": "09-12-2023",
    "changes": [
      "Fixed issue with contacts throwing an error when trying to merge",
      "Fixed issue with analytics not showing data",
      "Styling updates to currency field in pipeline form",
      "Updates to team list page",
      "Improvements to user dropdowns",
      "Small visual improvements to contact activity",
    ]
  },
  {
    "version": "2.4.1",
    "date": "08-15-2023",
    "changes": [
      "Fixed issue with unassigned filter not working, expect longer time to return results",
    ]
  },
  {
    "version": "2.4.0",
    "date": "08-07-2023",
    "changes": [
      "Pipeline card added to company details page",
      "Contact name in Pipeline table links to contact details page",
    ]
  },
  {
    "version": "2.3.0",
    "date": "07-31-2023",
    "changes": [
      "Add contact form submissions are now logged in activity feed",
      "Mailchimp newsletter subscription improvements",
      "New pipeline drawer with details and history log"
    ]
  },
  {
    "version": "2.2.0",
    "date": "07-18-2023",
    "changes": [
      "Contacts and Company table now have a 'Groups' column",
      "You can now collapse the sidebar no matter the size of the screen"
    ]
  },
  {
    "version": "2.1.0",
    "date": "07-05-2023",
    "changes": [
      "Under the surface updates to analytics page",
      "Fixes to names showing null when last name missing",
      "New Admin pages",
      "Revert activity icons"
    ]
  },
  {
    "version": "2.0.0",
    "date": "06-14-2023",
    "changes": [
      "Sidebar and topbar layout updates",
      "Login page updated",
      "Updates to dialog providers",
      "Contact and Company page layouts have been updated",
      "Sale Report Form gets a dedicated page",
      "Small updates to labels"
    ]
  },
  {
    "version": "1.23.7",
    "date": "06-08-2023",
    "changes": [
      "Pipeline: Fixed issue when adding decimal values to Sale amount",
    ]
  },
  {
    "version": "1.23.6",
    "date": "04-25-2023",
    "changes": [
      "Pipeline: Send email notification if new person is assigned to contact in pipeline.",
    ]
  },
  {
    "version": "1.23.5",
    "date": "03-08-2023",
    "changes": [
      "Changed default rowsPerPage to 50 across tables",
      "Sales report pdf: Styling/spacing changes",
      "Pipeline: Add-to-report button adds to sales report with new formatting",
      "Pipeline: Table header changed [Project title] -> [Project]",
      "Pipeline: Added batch assigning",
      "Pipeline: Closed won and closed lost get archived automatically",
      "Pipeline: Added createdAt to table; condensed actions",
    ]
  },
  {
    "version": "1.23.4",
    logout: true,
    "date": "01-19-2023",
    "changes": [
      "Fix issue with website items. Max of 1000 items reached. Increased to 2000 items"
    ]
  },
  {
    "version": "1.23.3",
    logout: true,
    "date": "01-18-2023",
    "changes": [
      "Fix issues with product form not finding collections",
      "Add new sale page 'Warehouse Sale'"
    ]
  },
  {
    "version": "1.23.2",
    logout: true,
    "date": "11-28-2022",
    "changes": [
      "Pipeline updates:",
      "Add to Report button is added in table."
    ]
  },
  {
    "version": "1.23.1",
    logout: true,
    "date": "11-01-2022",
    "changes": [
      "Pipeline updates:",
      "Company column added in",
      "Sorting by Phase is now default selected",
      "Ability to archive pipeline projects."
    ]
  },
  {
    "version": "1.23.0",
    logout: true,
    "date": "09-20-2022",
    "changes": [
      "Initial version of pipeline process now available",
      "Collections in website can now be reordered",
      "Admins can now delete form subsmissions",
      "Website product form styling changes"
    ]
  },
  {
    "version": "1.22.3",
    "date": "08-11-2022",
    "changes": [
      "Fixed issue with vip level not showing up when filtering by assigned to",
      "Added vip level to contact and company cards"
    ]
  },
  {
    "version": "1.22.2",
    "date": "07-27-2022",
    "changes": [
      "Fixed Analytics Top Products list"
    ]
  },
  {
    "version": "1.22.1",
    "date": "06-28-2022",
    "changes": [
      "Fix issue with companies search",
      "Adjust vip types"
    ]
  },
  {
    "version": "1.22.0",
    "date": "06-22-2022",
    logout: true,
    "changes": [
      "Next and previous navigation added to contacts and companies page",
      "Contact/Company activity now includes filtering by type",
      "Fixed issue where contacts/companies failed to tag in mailchimp",
      "Under the hood improvements to analytics",
      "Under the hood improvements to VIP (types coming soon)",
      "Under the hood improvements to login page",
    ]
  },
  {
    "version": "1.21.0",
    "date": "03-31-2022",
    "changes": [
      "Contacts and companies are being tagged in Mailchimp according to region (SF or LA). After adding region to a contact/company, a tag symbol appears in the group information to verify tagging status.",
    ]
  },
  {
    "version": "1.20.0",
    "date": "03-10-2022",
    "changes": [
      "Quick open contacts and companies from dashboard page and tables",
      "Add sale page option in homepage cms",
      "Change sales report text",
      "Add indicator arrows to analytics page"
    ]
  },
  {
    "version": "1.19.1",
    "date": "12-07-2021",
    "changes": [
      "Enable product views analytics",
      "Enable group analytics",
    ]
  },
  {
    "version": "1.19.0",
    "date": "11-17-2021",
    "changes": [
      "Add created by to contacts/companies",
      "Enable analytics",
    ]
  },
  {
    "version": "1.18.9",
    "date": "10-04-2021",
    "changes": [
      "Fix issue with contacts/companies unsubscribing during a merge",
      "Fix issue with Team Activity Feed not displaying activity after 5PM PST.",
    ]
  },
  {
    "version": "1.18.8",
    "date": "09-22-2021",
    "changes": [
      "Disables the creation of a new contact from a Google Calendar event",
      "Change style of buttons for Google Sign-In",
      "New links for privacy policy and terms of service"
    ]
  },
  {
    "version": "1.18.7",
    "date": "08-18-2021",
    "changes": [
      "Fix issue deleting last known save of sales report when using quick add report button from contact details page."
    ]
  },
  {
    "version": "1.18.6",
    "date": "08-12-2021",
    "changes": [
      "Button added on contact and company details page for adding to sales report.",
      "Update to gmail email signature editor. Images can now be resized.",
      "Added company search as option to sales report form",
      "Format button added when phone number is not formatted."
    ]
  },
  {
    "version": "1.18.5",
    "date": "07-27-2021",
    "changes": [
      "Fix rendering issue in the company's page",
    ]
  },
  {
    "version": "1.18.4",
    "date": "07-27-2021",
    "changes": [
      "Allow sales report draft to be saved.",
    ]
  },
  {
    "version": "1.18.3",
    "date": "07-23-2021",
    "changes": [
      "Fix render of all day events",
    ]
  },
  {
    "version": "1.18.2",
    "date": "07-20-2021",
    "changes": [
      "General calendar adjustments",
      "Add companies as attendees to calendar events",
      "Add warning dialog for expired Google Calendar authorization",
      "Add logo to “Assigned to” email notifications",
    ]
  },
  {
    "version": "1.18.1",
    "date": "07-01-2021",
    "changes": [
      "Add ability to create and download weekly sales report form",
    ]
  },
  {
    "version": "1.18.0",
    "date": "06-24-2021",
    "changes": [
      "Add first part of Google Calendar Integration",
    ]
  },
  {
    "version": "1.17.1",
    "date": "06-08-2021",
    "changes": [
      "Fix rendering issue with newsletter form",
      "Fix last name missing with newsletter form",
      "Enable add contact form submissions"
    ]
  },
  {
    "version": "1.17.0",
    "date": "05-25-2021",
    "changes": [
      "Allow email notifications for assignments of contacts or companies",
      "Fix mailchimp icon status",
      "Record trade program form submission in contact activity"
    ]
  },
  {
    "version": "1.16.4",
    "date": "05-15-2021",
    "changes": [
      "Enable newsletter form submissions",
    ]
  },
  {
    "version": "1.16.3",
    "date": "05-11-2021",
    "changes": [
      "Fix merge items without emails",
    ]
  },
  {
    "version": "1.16.2",
    "date": "05-11-2021",
    "changes": [
      "Enable merge dropdowns for contact and company",
      "Fixes lag on company select dropdown from new contact form",
      "Enable filter by product in download form submissions"
    ]
  },
  {
    "version": "1.16.1",
    "date": "04-22-2021",
    "changes": [
      "Show all types of Mailchimp activity",
      "Updates to task scroll behavior",
      "Automatic formating of phone numbers on newly created contacts and companies"
    ]
  },
  {
    "version": "1.16.0",
    "date": "04-19-2021",
    "changes": [
      "UI improvements and general fixes",
      "Automatically assign current user to company during quick create",
      "Fix issue with tasks not being assigned to correct user",
      "Improvements to search",
      "Add toggle between past/todays tasks and all tasks",
      "Add links to contacts created from form submissions",
      "Fix activity timeline to reflect correct date for notes",
      "Add company phone number to contact card",
    ]
  },
  {
    "version": "1.15.3",
    "date": "04-09-2021",
    "changes": [
      "Fix typo on creating group contact subscription",
    ]
  },
  {
    "version": "1.15.2",
    "date": "04-02-2021",
    "changes": [
      "Fix issues with null last names",
      "Add tasks and events on merging contacts and companies",
    ]
  },
  {
    "version": "1.15.1",
    "date": "03-31-2021",
    "changes": [
      "Add empty message to tasks",
      "Fix sort by selector label",
      "Fix text wrap on cards",
      "Fix mailchimp icon status"
    ]
  },
  {
    "version": "1.15.0",
    "date": "03-30-2021",
    "changes": [
      "New Calendar feature",
      "New Tasks feature",
      "Minor improvements to dropdowns and selectors"
    ]
  },
  {
    "version": "1.14.2",
    "date": "03-29-2021",
    "changes": [
      "Add images to the email signature",
    ]
  },
  {
    "version": "1.14.1",
    "date": "03-20-2021",
    "changes": [
      "Fix search for companies and contacts on reassigning groups",
    ]
  },
  {
    "version": "1.14.0",
    "date": "03-19-2021",
    "changes": [
      "Temporary reporting button added",
      "Force logout when a new version is available",
      "Improvements to search results in contacts and companies",
      "Fix list dropdowns for mailchimp settings in safari",
      "Improvements to company dropdowns.",
    ]
  },
  {
    "version": "1.13.4",
    "date": "03-18-2021",
    "changes": [
      "Fix unexpected error on contacts and companies table. Issue was in filters.",
    ]
  },
  {
    "version": "1.13.3",
    "date": "03-17-2021",
    "changes": [
      "Fix issue with edit company. Issue when email was empty",
    ]
  },
  {
    "version": "1.13.2",
    "date": "03-15-2021",
    "changes": [
      "Last name not required on contact form",
      "Get realtime mailchimp status",
    ]
  },
  {
    "version": "1.13.1",
    "date": "03-15-2021",
    "changes": [
      "Fix mailchimp_id and email uppercase issues",
    ]
  },
  {
    "version": "1.13.0",
    "date": "03-12-2021",
    "changes": [
      "Fix routing in contact details",
      "Change team activity from last week to 7 days",
      "Fix update on email signature",
      "Add clear button on table search",
      "Fix company select in create/update contact",
      "Fix mailchimp status error",
      "Disable email composer when no email present",
      "Fix emails not recording on certain entries"
    ]
  },
  {
    "version": "1.12.0",
    "date": "03-09-2021",
    "changes": [
      "Merge contacts & companies."
    ]
  },
  {
    "version": "1.11.1",
    "date": "03-04-2021",
    "changes": [
      "Fix form submissions creating duplicate of contacts."
    ]
  },
  {
    "version": "1.11.0",
    "date": "03-03-2021",
    "changes": [
      "Fix search results in contacts and company tables. Was getting inaccurate results.",
      "Known issues: form submissions creating duplicates of contacts"
    ]
  },
  {
    "version": "1.10.4",
    "date": "03-01-2021",
    "changes": [
      "Contact & Company totals now fetching correct result",
    ]
  },
  {
    "version": "1.10.3",
    "date": "02-17-2021",
    "changes": [
      "Fix Download File product thumbnail in safari. Prevent stretching of image.",
      "Fix invalid date error in changelog for safari.",
    ]
  },
  {
    "version": "1.10.2",
    "date": "02-01-2021",
    "changes": [
      "Fix/Enable contact form submissions activity. Contact Activity is now correctly being logged into the contact's details",
      "Enable CRM in production",
      "Form submissions emails now converted to lowercase"
    ]
  },
  {
    "version": "1.10.1",
    "date": "01-28-2021",
    "changes": [
      "Protected all routes and added permisions for certain group access",
      "Under the hood performance improvements to sidebar"
    ]
  },
  {
    "version": "1.10.0",
    "date": "01-26-2021",
    "changes": [
      "Added filter team activity by date",
      "Fixed issue with displaying trade program file submissions",
      "Added User roles and groups (i.e administrator, editor, sales rep)"
    ]
  },
  {
    "version": "1.9.1",
    "date": "01-19-2021",
    "changes": [
      "Fixed url issue with product details/form page. Was preventing editing of a product/material",
    ]
  },
  {
    "version": "1.9.0",
    "date": "01-12-2021",
    "changes": [
      "Batch operations for reassigning groups",
      "Website pages navigation has been updated. Tabs removed and relocated to sidebar",
      "New Sale pages added",
      "Trade Program form table enabled",
      "Trade program page content is now editable",
      "Minor UI updates to font, color, padding",
      "Fixed inline style issues with email",
      "Under the hood checks for production"
    ]
  },
  {
    "version": "1.8.1",
    "date": "01-05-2021",
    "changes": [
      "Safari Browser Dashboard activity shows the proper height for each timeline item",
      "Collapsed drawer icon moved to left of top appbar."
    ]
  },
  {
    "version": "1.8.0",
    "date": "01-04-2021",
    "changes": [
      "Added Email activity in dashboard",
      "Added Option to deauthorize gmail settings from Connect App",
      "Contact table contact name is now first name + last name",
      "Contact table contact name is bolder + clickable",
      "Company table company name is bolder + clickable",
      "Email address in details page opens up composer",
      "Web addresses open in a new tab",
      "Dashboard notes and emails details have a light color blue box for clarity",
      "Form labels updated to 'Save' instead of 'Edit'",
      "'Assigned to' changed to 'Assign to' in table actions",
      "Updated gmail authorization process. Made simpler",
      "Issues with email signature resolved",
      "Fixed rerendering issues in activity after sending email"
    ]
  },
  {
    "version": "1.7.0",
    "date": "12-28-2020",
    "changes": [
      "Activity Dashboard: View team activity in the dashboard tab. Currently listing notes, contact and company creation and deletion. Can filter by employee",
      "Add name in my account tab. You can change your display name",
      "Gmail integration: Authenticate gmail in settings tab and compose and send emails to contacts/companies",
      "Email Activity: Emails sent will be logged in contact/company activity",
      "User signature: Add email signatures in settings tab",
      "Restrict delete to admin only",
      "Login page UI update",
      "Contact/Company Input labels are now bolder and slightly bigger",
      "Company Action card tabs are now bolder and slighly bigger",
      "Display name will soon change from email to users name in my account"
    ]
  },
  {
    "version": "1.6.0",
    "date": "11-16-2020",
    "changes": [
      "Added Company notes.",
      "Added Batch operations in contact and company tables. Look for 'Actions' button after selecting rows.",
      "Added Companies with emails can now sync to mailchimp.",
      "Added Mailchimp subscribe/unsubscribe button",
      "Added Helper text added to contact and company forms around groups that are synced with mailchimp.",
      "Changed Hide 'entered by' in notes when no value exists. (import)",
      "Create company process during contact creation now selects after creating.",
      "Mailchimp and group sync. Contact/Company do not get automatically removed from group when unsubscribed."
    ]
  },
  {
    "version": "1.5.0",
    "date": "10-22-2020",
    "changes": [
      "Contacts and Company detailed filtering.",
      "Mailchimp activity in contact details page",
      "Unassigned icon, alert and filter",
      "VIP status badge",
      "Update contact and company details UI. Added cards",
      "Updated contact table with popover Contact card.",
      "Style classes were not being passed down fixed"
    ]
  },
  {
    "version": "1.4.6",
    "date": "10-15-2020",
    "changes": [
      "Reference missing in progress page after creating a product or material",
    ]
  },
  {
    "version": "1.4.5",
    "date": "09-21-2020",
    "changes": [
      "Link search now shows all products or materials",
    ]
  },
  {
    "version": "1.4.4",
    "date": "09-09-2020",
    "changes": [
      "CRM items disabled in production.",
      "Split groups into three types: mailings, region, industry.",
      "Note field added to products/materials. This will show up on the website next to 'request more info'."
    ]
  },
  {
    "version": "1.4.3",
    "date": "09-03-2020",
    "changes": [
      "Re-enabled edit and delete note.",
    ]
  },
  {
    "version": "1.4.2",
    "date": "08-31-2020",
    "changes": [
      "Fixed issue with updating and deleting contact",
      "Fixed issue with routing to contact detail page after create",
      "Fixed issue with mailchimp subscriptions",
      "Updated contact details page ui and notes form. Added Activity card to display notes and created at.",
      "Temporarily disabled edit and delete note. Expect it back in the next release."
    ]
  },
  {
    "version": "1.4.1",
    "date": "08-26-2020",
    "changes": [
      "Added State/zip to download form table",
    ]
  },
  {
    "version": "1.4.0",
    "date": "08-12-2020",
    "changes": [
      "Enabled form submissions from 33SIXTY websites to be viewed.",
    ]
  },
  {
    "version": "1.3.1",
    "date": "07-14-2020",
    "changes": [
      "Deployment Button was not triggering a deployment to website",
    ]
  },
  {
    "version": "1.3.0",
    "date": "07-07-2020",
    "changes": [
      "Product Preview: see a preview of a product before publishing",
      "Sidebar UI has changed to help with scaling",
      "Appbar with actions moved to top",
      "UI Style changes throughout pages for scaling"
    ]
  },
  {
    "version": "1.2.0",
    "date": "05-11-2020",
    "changes": [
      "Homepage Content: is now manageable and can be sorted by drag and drop",
      "Contact Page Content: is now manageable",
      "About Us Page Content: is now manageable",
      "Spec/CAD File title missing causing issue in database fixed"
    ]
  },
  {
    "version": "1.1.0",
    "date": "04-30-2020",
    "changes": [
      "Materials are now separated by Indoor and Outdoor.",
    ]
  },
  {
    "version": "1.0.10",
    "date": "04-23-2020",
    "changes": [
      "Under the hood improvements to API and Data",
    ]
  },
  {
    "version": "1.0.9",
    "date": "04-21-2020",
    "changes": [
      "PDFs were titled as 'untitled' in database.",
    ]
  },
  {
    "version": "1.0.8",
    "date": "04-17-2020",
    "changes": [
      "Material Images: materials now have a new image limit of 20.",
      "Form layout: changed to accomodate new image limit.",
      "Loading: animation now shows when content is loading."
    ]
  },
  {
    "version": "1.0.7",
    "date": "04-09-2020",
    "changes": [
      "Crop Images: When adding a new image. An edit button will appear allowing a 3:2 aspect ratio crop. You can only do this when adding an image. The option will not show after the image has been published. If the original image is smaller than the crop box, no crop will be made.",
    ]
  },
  {
    "version": "1.0.6",
    "date": "04-04-2020",
    "changes": [
      "Link Product: You can now link a product from the collection page",
      "Year/Description: Now leaving year and description empty will save correctly",
      "Sort Alphabetically: Adding or modifying a product now sorts the collection alphabetically",
      "Seeing Multiple: An error with the cache was showing multiples of products. After signing out the cache will clear."
    ]
  },
  {
   "version": "1.0.5",
   "date": "04-01-2020",
   "changes": [
      "Change Log to keep track of what has been fixed or added.",
    ]
  }
];